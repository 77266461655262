const cookiesButtons = document.querySelectorAll(
        '.cookies__button button'
    ),
    cookiesBanner = document.querySelector('.cookies'),
    savedCookies = readCookie('karma_cookies');

window.dataLayer = window.dataLayer || [];
function gtag() {
    dataLayer.push(arguments);
}

cookiesButtons.forEach(el => {
    el.addEventListener('click', () => {
        saveCookies(el.getAttribute('data-cookies'));
        if (cookiesBanner) {
            setTimeout(() => {
                cookiesBanner.parentNode.removeChild(cookiesBanner);
            }, 500);
        }
    });
});
allConsentDenied();

if (savedCookies == 'accept') {
    saveCookies('accept');
} else if (cookiesBanner) {
    setTimeout(() => {
        cookiesBanner.classList.remove('hide');
    }, 1250);
}

function saveCookies(type) {
    const renderCookies = async () => {
        const { url } = ajax;
        const formData = new FormData();
        formData.append('action', 'karma_cookies_save');

        formData.append('type', type);
        try {
            const req = await fetch(
                url,
                {
                    method: 'POST',
                    body: formData,
                }
            );
            const response = await req.json();
            updateCookies(response.type, response.scripts);
        } catch (error) {
            console.log(error);
        }
    };

    renderCookies();
    if (cookiesBanner) {
        cookiesBanner.classList.add('hide');
    }
}

function updateCookies(type, scripts) {
    createCookie('karma_cookies', type, 60);
    if (type == 'accept') {
        allConsentGranted();
        for (let object in scripts) {
            let item = scripts[object];
            jQuery(function( $ ) {
                if (item.header) {
                    $('head').append(item.header);
                }
                if (item.body) {
                    $('body').prepend(item.body);
                }
            });
        }
    }
}

function createCookie(name, value, days) {
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toGMTString();
    } else {
        expires = '';
    }
    document.cookie =
        encodeURIComponent(name) +
        '=' +
        encodeURIComponent(value) +
        expires +
        '; path=/';
}

function readCookie(name) {
    var nameEQ = encodeURIComponent(name) + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0)
            return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
}

function allConsentDenied() {
    gtag('consent', 'default', {
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        ad_storage: 'denied',
        analytics_storage: 'denied',
    });
}

function allConsentGranted() {
    gtag('consent', 'update', {
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        ad_storage: 'granted',
        analytics_storage: 'granted',
    });
}
